import React, { useEffect, useState } from 'react'
import axios from 'axios'
import useWindowDimensions from './WindowDimensions'
import { Link } from 'react-router-dom'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

function Awards(props) {


    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        speed: 250,
        autoplaySpeed: 2000,
        cssEase: "linear",
        centerMode: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            }
        ]
    }

    useEffect(() => {
        getAwards()
    }, [])

    const [awards, setAwards] = useState([])
    const { height, width } = useWindowDimensions()

    const getAwards = () => {
        axios.get(`${props.api}/api/content/items/awards`, {
            headers: {
                "Content-Type": "application/json",
                'api-key': props.acctok,
                'Cache-Control': 'no-cache',
            }
        })
            .then(res => {
                setAwards(res.data)
            })
            .catch(error => {
                console.log(error)
            })
    }

    const savedAwards = awards.map(award => (
        <div key={award._id} className='award'>
            <img src={`${props.api}/storage/uploads${award.image.path}`} className='awards-page-pic' />
        </div>
    ))

    const savedAwardsMobile = awards.slice(0, 3).map(award => (
        <div key={award._id} className='home-award-wrapper'>
            <img src={`${props.api}/storage/uploads${award.image.path}`} className='home-award-pic' />
        </div>
    ))

    return (

        <div className='awardsPage container-fluid'>
            <h1 className='secHeading'>AWARDS</h1>
            <div className='awards-slider'>
                <Slider {...settings}>
                    {savedAwards}
                </Slider>
            </div>
            <div className='text-center mt-4'>
                <Link to='/awards'> <button type='button' type='submit' className='btn btn-contact-message'>SEE ALL</button> </Link>
            </div>
        </div>

        // <div className='awards-wrapper' id='awards'>
        //     <div className='container-fluid awards-body'>
        //         <h1>AWARDS</h1>
        //         <div className='awards-slider'>
        //             {/* {width > 768 ? savedAwards : savedAwardsMobile} */}
        //             <Slider {...settings}>
        //                 {savedAwards}
        //             </Slider>
        //         </div>
        //         <div className='text-center mt-4'>
        //             <Link to='/awards'> <button type='button' type='submit' className='btn btn-contact-message'>SEE ALL</button> </Link>
        //         </div>
        //     </div>
        // </div>
    )
}

export default Awards